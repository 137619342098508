import { FC } from "react";

import classes from "./ExperienceItem.module.css";

interface Props {
  name: string;
  logo: string;
  role: string;
  responsibilities: string[];
  skills: string[];
  isBorderBottom: boolean;
}

const ExperienceItem: FC<Props> = ({
  name,
  logo,
  role,
  responsibilities,
  skills,
  isBorderBottom,
}) => {
  return (
    <div
      className={classes.item}
      style={{ borderBottom: `${isBorderBottom ? "2px solid" : ""}` }}
    >
      <div className={classes.company}>
        <div className={classes.logo}>
          <img src={logo} alt={name} />
        </div>
        <div className={classes.description}>
          <div className={classes.name}>{name}</div>
          <div className={classes.job}>{role}</div>
        </div>
      </div>
      <span>Contribution</span>
      <div className={classes.responsibilities}>
        <ul>
          {responsibilities.map((res) => (
            <li>{res}</li>
          ))}
        </ul>
      </div>
      <span>Tech Stack</span>
      <div className={classes.skills}>
        <ul>
          {skills.map((skill) => (
            <li>{skill}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ExperienceItem;
