import { FC } from "react";
import { motion } from "framer-motion";

import classes from "./About.module.css";
import ProfilePhoto from "../../assets/ProfilePhoto.jpg";
import JS from "../../assets/js.png";
import HTML from "../../assets/HTML.png";
import CSS from "../../assets/CSS.png";
import React from "../../assets/React.png";
import Redux from "../../assets/Redux.png";
import ReactRouter from "../../assets/React-Router.png";
import MUI from "../../assets/MUI.png";
import TestingLibrary from "../../assets/TestingLibrary.jpg";

const About: FC = () => {
  return (
    <motion.div
      className={classes.about}
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: { duration: 2 },
      }}
      exit={{ opacity: 0, transition: { duration: 2 } }}
    >
      <div className={classes["profile-photo"]}>
        <img src={ProfilePhoto} alt="Avnish Ranwa" />
      </div>
      <div className={classes.description}>
        <ul>
          <li>
            Software Engineer with background in Web Development with relevant
            experience in ReactJS
          </li>
          <li>3 &#9733; at Codechef</li>
          <li>Hackathons; DSA; Competitive Programming</li>
        </ul>
      </div>
      <p>Tech Stack</p>
      <div className={classes["tech-stack"]}>
        <img src={HTML} alt="HTML" />
        <img src={CSS} alt="CSS" />
        <img src={JS} alt="JavaScript" />
      </div>
      <div className={classes["tech-stack"]}>
        <img src={React} alt="React" />
        <img src={Redux} alt="Redux" />
        <img src={ReactRouter} alt="ReactRouter" />
        <img src={MUI} alt="MUI" />
      </div>
      <div className={classes["tech-stack"]}>
        <img src={TestingLibrary} alt="TestingLibrary" />
      </div>
    </motion.div>
  );
};

export default About;
