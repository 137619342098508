import { FC } from "react";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

import classes from "./MobileNav.module.css";
import { RESUME_LINK } from "../Constants";

interface Props {
  onCloseNav: () => void;
}

const MobileNav: FC<Props> = ({ onCloseNav }) => {
  return (
    <motion.div
      className={classes["mobile-links"]}
      initial={{ x: 500 }}
      animate={{ x: 0, transition: { stiffness: 50, type: "spring" } }}
      exit={{
        y: "95vh",
        transition: { stiffness: 50, type: "spring" },
      }}
    >
      <CloseIcon
        sx={{ position: "fixed", top: "0.5rem", right: "1rem" }}
        onClick={onCloseNav}
      />
      <ul>
        <motion.li
          initial={{ x: 1000 }}
          animate={{
            x: 0,
            transition: { delay: 0.5, stiffness: 30, type: "spring" },
          }}
        >
          <NavLink
            to="about"
            className={({ isActive }) => (isActive ? classes.active : "")}
            onClick={onCloseNav}
          >
            About Me
          </NavLink>
        </motion.li>
        <motion.li
          initial={{ x: 1000 }}
          animate={{
            x: 0,
            transition: { delay: 0.55, stiffness: 30, type: "spring" },
          }}
        >
          <NavLink
            to="education"
            className={({ isActive }) => (isActive ? classes.active : "")}
            onClick={onCloseNav}
          >
            Education
          </NavLink>
        </motion.li>
        <motion.li
          initial={{ x: 1000 }}
          animate={{
            x: 0,
            transition: { delay: 0.6, stiffness: 30, type: "spring" },
          }}
        >
          <NavLink
            to="experience"
            className={({ isActive }) => (isActive ? classes.active : "")}
            onClick={onCloseNav}
          >
            Experience
          </NavLink>
        </motion.li>
        <motion.li
          initial={{ x: 1000 }}
          animate={{
            x: 0,
            transition: { delay: 0.65, stiffness: 30, type: "spring" },
          }}
        >
          <NavLink
            to="projects"
            className={({ isActive }) => (isActive ? classes.active : "")}
          >
            Projects
          </NavLink>
        </motion.li>
        <motion.li
          initial={{ x: 1000 }}
          animate={{
            x: 0,
            transition: { delay: 0.7, stiffness: 30, type: "spring" },
          }}
        >
          <a href={`${RESUME_LINK}`} target="_blank" rel="noreferrer">
            Resume
            <ArrowOutwardIcon fontSize="small" sx={{ padding: 0 }} />
          </a>
        </motion.li>
      </ul>
    </motion.div>
  );
};

export default MobileNav;
