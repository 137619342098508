import { NavLink } from "react-router-dom";

import classes from "./Logo.module.css";

const Logo = () => {
  return (
    <div className={classes.logo}>
      <NavLink
        to="/"
        className={({ isActive }) => (isActive ? classes.hidden : "")}
      >
        <span>Avnish Ranwa</span>
      </NavLink>
    </div>
  );
};

export default Logo;
