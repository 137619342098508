import { FC } from "react";
import { motion } from "framer-motion";

import classes from "./Experience.module.css";
import ExperienceItem from "./ExperienceItem";

import Softsensor from "../../assets/Softsensor.jpeg";
import RoboMQ from "../../assets/RoboMQ.jpeg";
import Kredey from "../../assets/Kredey.jpeg";

const COMPANIES = [
  {
    name: "Softsensor.ai",
    logo: Softsensor,
    role: "Software Developer",
    responsibilities: [
      "Created User Interface components following the figma design using Material UI",
      "Integrating APIs with the help of Axios",
      "Using React Query/Tanstack Query for caching data of APIs",
      "Written unit test cases using Testing Library and Jest, mocking functions and HTTP requests using Mock Service Worker (MSW)",
      "Bug fixes related to components",
    ],
    skills: [
      "React.js",
      "Redux.js",
      "Tanstack Query",
      "Typescript",
      "Javascript",
      "Material UI",
      "React Testing Library",
    ],
  },
  {
    name: "RoboMQ",
    logo: RoboMQ,
    role: "Associate Software Engineer Intern",
    responsibilities: [
      "Created SCIM connectors for DocuSign, Udemy, Thales, BoxDev, and Scoro for integration in Hire2Retire",
      "Provisioning and de-provisioning of users using HTTP methods such as POST, PATCH and DELETE to perform operations of creating, updating, terminating and rehiring users",
    ],
    skills: [
      "Spring",
      "Java",
      "Linux",
      "JUnit Testing",
      "Jenkins",
      "Git",
      "Postman",
    ],
  },
  {
    name: "Kredey",
    logo: Kredey,
    role: "Frontend Developer Intern",
    responsibilities: [
      "Built company's website from scratch",
      "Designed a built-in public chat section",
      "Designed a page for uploading documents and profile picture of users with functionality of cropping the profile picture",
    ],
    skills: ["React.js", "HTML", "CSS", "Javascript", "Google Firebase"],
  },
];

const Experience: FC = () => {
  return (
    <motion.div
      className={classes.experience}
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: { duration: 2 },
      }}
      exit={{ opacity: 0, transition: { duration: 2 } }}
    >
      {COMPANIES.map((company, idx) => (
        <ExperienceItem
          key={company.name}
          name={company.name}
          logo={company.logo}
          role={company.role}
          responsibilities={company.responsibilities}
          skills={company.skills}
          isBorderBottom={idx !== COMPANIES.length - 1}
        />
      ))}
    </motion.div>
  );
};

export default Experience;
