import { FC, useState } from "react";
import { NavLink } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

import MobileNav from "./MobileNav";
import classes from "./Navbar.module.css";
import Logo from "./Logo";
import { RESUME_LINK } from "../Constants";

const Navbar: FC = () => {
  const [isMobileNavVisible, setIsMobileNavVisible] = useState<boolean>(false);

  const openMobileNav = () => {
    setIsMobileNavVisible(true);
    setTimeout(() => {
      const mainElement = document.querySelector("main");
      mainElement!.style.position = "fixed";
    }, 500);
  };

  const closeMobileNav = () => {
    setIsMobileNavVisible(false);
    const mainElement = document.querySelector("main");
    mainElement!.style.position = "relative";
  };

  return (
    <header>
      <div className={classes["mobile-header"]}>
        <Logo />
        <IconButton onClick={openMobileNav}>
          <MenuIcon fontSize="medium" sx={{ color: "white" }} />
        </IconButton>
        <AnimatePresence>
          {isMobileNavVisible && <MobileNav onCloseNav={closeMobileNav} />}
        </AnimatePresence>
      </div>
      <div className={classes["laptop-header"]}>
        <Logo />
        <div className={classes.links}>
          <NavLink
            to="about"
            className={({ isActive }) => (isActive ? classes.active : "")}
          >
            About Me
          </NavLink>
          <NavLink
            to="education"
            className={({ isActive }) => (isActive ? classes.active : "")}
          >
            Education
          </NavLink>
          <NavLink
            to="experience"
            className={({ isActive }) => (isActive ? classes.active : "")}
          >
            Experience
          </NavLink>
          <NavLink
            to="projects"
            className={({ isActive }) => (isActive ? classes.active : "")}
          >
            Projects
          </NavLink>
          <a
            href={`${RESUME_LINK}`}
            target="_blank"
            rel="noreferrer"
            style={{ display: "flex" }}
          >
            Resume
            <ArrowOutwardIcon fontSize="small" />
          </a>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
