import { FC } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Education from "./pages/Education/Education";
import Experience from "./pages/Experience/Experience";

const App: FC = () => {
  const location = useLocation();
  const locationArr = location.pathname.split("/") ?? [];

  return (
    <>
      <AnimatePresence mode="wait">
        <Routes location={location} key={locationArr[1]}>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/education" element={<Education />} />
          <Route path="/experience" element={<Experience />} />
        </Routes>
      </AnimatePresence>
    </>
  );
};

export default App;
