import { motion } from "framer-motion";

import classes from "./Education.module.css";

interface EDU {
  institution: string;
  degree: string;
  branch?: string;
  cgpa?: number;
  percentage?: number;
}

const EDUCATION: Array<EDU> = [
  {
    institution: "JK Lakshmipat University",
    degree: "Bachelor's of Technology",
    branch: "Computer Science and Engineering",
    cgpa: 7.9,
  },
  {
    institution: "IIT Gandhinagar",
    degree: "Bachelor's of Technology (Non-Degree Programme)",
    branch: "Computer Science and Engineering",
    cgpa: 7.81,
  },
  {
    institution: "Mahala Residential Public School",
    degree: "12th Grade",
    branch: "Physics . Chemistry . Maths",
    percentage: 81,
  },
  {
    institution: "Sanskar School",
    degree: "10th Grade",
    cgpa: 10.0,
  },
];

const Education = () => {
  return (
    <motion.div
      className={classes.education}
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: { duration: 2 },
      }}
      exit={{ opacity: 0, transition: { duration: 2 } }}
    >
      {EDUCATION.map((edu, idx) => (
        <>
          <div className={classes.university}>
            <span>{edu.institution}</span>
            <span>{edu.degree}</span>
            <span>{edu.branch}</span>
            <span>{edu.cgpa && `CGPA ${edu.cgpa}`}</span>
            <span>{edu.percentage && `Percentage ${edu.percentage}`}</span>
          </div>
          {idx !== EDUCATION.length - 1 && <hr />}
        </>
      ))}
    </motion.div>
  );
};

export default Education;
