import { FC } from "react";
import { motion } from "framer-motion";

import classes from "./Home.module.css";

const Home: FC = () => {
  return (
    <motion.div
      className={classes.home}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 2 } }}
      exit={{ opacity: 0, transition: { duration: 1 } }}
    >
      <h1>Avnish Ranwa</h1>
      <div className={classes.position}>
        <h2>Software Engineer</h2>
        <motion.h2
          initial={{ x: 1000 }}
          animate={{
            x: 0,
            transition: { delay: 0.5, stiffness: 50, type: "spring" },
          }}
          exit={{ x: 1000, transition: { x: 1000, delay: 0.5 } }}
        >
          &nbsp;@ Softsensor.ai
        </motion.h2>
      </div>
    </motion.div>
  );
};

export default Home;
