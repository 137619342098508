import { FC } from "react";
import { motion } from "framer-motion";
import { IconButton } from "@mui/material";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import "./Footer.module.css";
import { GITHUB_URL, LINKEDIN_URL } from "../Constants";

const Footer: FC = () => {
  return (
    <motion.footer
      initial={{ y: 100 }}
      animate={{ y: 0, transition: { duration: 2 } }}
    >
      <IconButton
        onClick={() => {
          window.open(GITHUB_URL, "_blank", "noopener");
        }}
      >
        <GitHubIcon fontSize="large" sx={{ color: "white" }} />
      </IconButton>
      <IconButton
        onClick={() => {
          window.open(LINKEDIN_URL, "_blank", "noopener");
        }}
      >
        <LinkedInIcon fontSize="large" sx={{ color: "white" }} />
      </IconButton>
    </motion.footer>
  );
};

export default Footer;
